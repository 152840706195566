@import 'assets/styles/mixins.scss';

.settings {
  position: fixed;
  top: 0;
  right: 0;
  background: $white;
  width: 350px;
  z-index: 999;
  height: 100%;
  transform: translate3d(350px, 0, 0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.settingsOpened {
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 200px -20px rgba(57, 55, 73, 0.4);
}

.close {
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
  font-size: 20px;
  float: right;
  padding: 4px 2px;
  opacity: 0.4;

  &:hover {
    opacity: 0.8;
  }
}

.radioButton {
  padding: 0 22px;
}

.container {
  padding: rem(22) rem(20);
  width: 350px;
}

.title {
  font-weight: bold;
  border-bottom: 1px solid $gray-border;
  padding-bottom: rem(20);
  margin-bottom: rem(20);

  :global(.fa) {
    float: right;
    cursor: pointer;
    padding: 4px 2px;
    opacity: 0.4;

    &:hover {
      opacity: 0.8;
    }
  }
}

.description {
  font-size: 12px;
  margin-bottom: rem(20);
  color: $text-lighten;
}

.item {
  margin-bottom: rem(15);
}

.itemLabel {
  position: relative;
  top: 2px;
  margin-left: 10px;
}

/* Settings for Chat Module */
.mediah2 {
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #223645;
  margin: 0;
}

.mediah4 {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #647589;
  margin: 0;
}

.radioButtonChat {
  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  padding: 4px 30px;
  min-height: 30px;
  min-width: 140px;
  background-color: #eff1f2 !important;
  color: #223645 !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: none !important;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.radioButtonChatActive {
  color: #fff !important;
  background-color: #1c9dea !important;
  box-shadow: -1px 10px 16px -10px #1c9dea !important;
}

.listItemAction {
  color: #fff !important;
  background-color: #1c9dea !important;
  box-shadow: -1px 10px 16px -10px #1c9dea !important;
}

.header {
  background-color: white;
  opacity: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  // line-height: 40px;
  padding: 1rem 1rem;
  -webkit-box-shadow: 0 3px 5px 2px #eff7fe;
  box-shadow: 0 3px 5px 2px #eff7fe;
  z-index: 999;
}

.footer {
  background-color: white;
  position: sticky;
  // bottom: 0;
  // line-height: 40px;
  padding: 1rem 1rem;
  -webkit-box-shadow: 0 3px 5px 2px #eff7fe;
  box-shadow: 0 -4px 5px 2px #eff7fe;
  z-index: 999;
}

.headerName {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #223645;
}

.chatContent {
  background-color: #eff7fe;
  background-image: url('./chatbg.png');
  background-repeat: repeat;
  min-height: calc(100vh - 10rem);
  padding: 0 0 5px 0;
}

.emoji {
  font-size: 20px;
}

.meetButton {
  position: absolute;
  bottom: 0;
  width: 100px;
  z-index: 2;
  margin: 25px 35px;
}
